import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./auth/contexts/AuthProvider";

// Admin
const Admin = lazy(() => import("./admin/pages/Admin"));
const Dashboard = lazy(() => import("./admin/pages/Dashboard"));
const Faq = lazy(() => import("./admin/pages/Faq"));
const HelpCenter = lazy(() => import("./admin/pages/HelpCenter"));
const Home = lazy(() => import("./admin/pages/Home"));
const Profile = lazy(() => import("./admin/pages/Profile"));
const ProfileActivity = lazy(() => import("./admin/pages/ProfileActivity"));
const ProfileInformation = lazy(
  () => import("./admin/pages/ProfileInformation")
);
const ProfilePassword = lazy(() => import("./admin/pages/ProfilePassword"));

// Auth
const ForgotPassword = lazy(() => import("./auth/pages/ForgotPassword"));
const ForgotPasswordSubmit = lazy(
  () => import("./auth/pages/ForgotPasswordSubmit")
);
const Login = lazy(() => import("./auth/pages/Login"));
// const Register = lazy(() => import("./auth/pages/Register"));

// Videos
const VideoManagement = lazy(() => import("./videos/pages/VideoManagement"));

// Programs
const ProgramManagement = lazy(
  () => import("./programs/pages/ProgramManagement")
);
// Categories
const CategoryManagement = lazy(
  () => import("./categories/pages/CategoryManagement")
);
// Daily tips
const DailyTipManagement = lazy(
  () => import("./dailytips/pages/DailytipsManagement")
);
// Collections
const CollectionManagement = lazy(
  () => import("./collections/pages/CollectionManagement")
);

// Dynamic app settings
const DynamicSettingsManagement = lazy(
  () => import("./dynamicsettings/pages/DynamicSettingsManagement")
);

// Stripe management
const StripeManagement = lazy(() => import("./stripe/pages/StripeManagement"));

// mappings
const MappingsManagement = lazy(
  () => import("./mappings/pages/MappingsManagement")
);

// Core
const Forbidden = lazy(() => import("./core/pages/Forbidden"));
const NotFound = lazy(() => import("./core/pages/NotFound"));
const UnderConstructions = lazy(
  () => import("./core/pages/UnderConstructions")
);

// Landing
// const Landing = lazy(() => import("./landing/pages/Landing"));

// Users
const UserManagement = lazy(() => import("./users/pages/UserManagement"));

export const RouterWrapper = () => {
  const { isLoggedIn } = useAuth();
  return <Routes>{isLoggedIn() ? privateRoutes() : publicRoutes()}</Routes>;
};

const privateRoutes = () => {
  return (
    <>
      <Route path="/" element={<Navigate to={"admin"} replace />} />
      <Route path="admin" element={<Admin />}>
        <Route path="" element={<Home />} />
        <Route path="videos" element={<VideoManagement />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="faq" element={<Faq />} />
        <Route path="help" element={<HelpCenter />} />
        <Route path="profile" element={<Profile />}>
          <Route path="" element={<ProfileActivity />} />
          <Route path="information" element={<ProfileInformation />} />
          <Route path="password" element={<ProfilePassword />} />
        </Route>
        <Route path="programs" element={<ProgramManagement />} />
        <Route path="collections" element={<CollectionManagement />} />
        <Route path="categories" element={<CategoryManagement />} />
        <Route path="dailytips" element={<DailyTipManagement />} />
        <Route path="user-management" element={<UserManagement />} />
        <Route path="dynamicsettings" element={<DynamicSettingsManagement />} />
        <Route path="stripe" element={<StripeManagement />} />
        <Route path="mappings" element={<MappingsManagement />} />
      </Route>
      <Route path="*" element={<Navigate to={`/`} replace />} />
    </>
  );
};

const publicRoutes = () => {
  return (
    <>
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="forgot-password-submit" element={<ForgotPasswordSubmit />} />
      <Route path="login" element={<Login />} />
      {/* <Route path="register" element={<Register />} /> */}
      <Route path="under-construction" element={<UnderConstructions />} />
      <Route path="403" element={<Forbidden />} />
      <Route path="404" element={<NotFound />} />
      <Route path="/" element={<Navigate to={"login"} replace />} />
      <Route path="*" element={<Navigate to={`404`} replace />} />
    </>
  );
};

const AppRoutes = () => {
  return <RouterWrapper />;
  // return <div>hello</div>;

  // return (
  //   <Routes>
  //     <Route path="/" element={<Navigate to={"/admin"} replace />} />
  //     <PrivateRoute path="admin" element={<Admin />}>
  //       <PrivateRoute path="/" element={<Home />} />
  //       <PrivateRoute path="videos" element={<VideoManagement />} />
  //       <PrivateRoute path="dashboard" element={<Dashboard />} />
  //       <PrivateRoute path="faq" element={<Faq />} />
  //       <PrivateRoute path="help" element={<HelpCenter />} />
  //       <PrivateRoute path="profile" element={<Profile />}>
  //         <PrivateRoute path="/" element={<ProfileActivity />} />
  //         <PrivateRoute path="information" element={<ProfileInformation />} />
  //         <PrivateRoute path="password" element={<ProfilePassword />} />
  //       </PrivateRoute>
  //       <PrivateRoute path="programs" element={<ProgramManagement />} />
  //       <PrivateRoute path="collections" element={<CollectionManagement />} />
  //       <PrivateRoute path="categories" element={<CategoryManagement />} />
  //       <PrivateRoute path="dailytips" element={<DailyTipManagement />} />
  //       <PrivateRoute path="user-management" element={<UserManagement />} />
  //       <PrivateRoute
  //         path="dynamicsettings"
  //         element={<DynamicSettingsManagement />}
  //       />
  //       <PrivateRoute path="stripe" element={<StripeManagement />} />
  //       <PrivateRoute path="mappings" element={<MappingsManagement />} />
  //     </PrivateRoute>
  //     <Route path="forgot-password" element={<ForgotPassword />} />
  //     <Route path="forgot-password-submit" element={<ForgotPasswordSubmit />} />
  //     <Route path="login" element={<Login />} />
  //     {/* <Route path="register" element={<Register />} /> */}
  //     <Route path="under-construction" element={<UnderConstructions />} />
  //     <Route path="403" element={<Forbidden />} />
  //     <Route path="404" element={<NotFound />} />
  //     <Route
  //       path="*"
  //       element={<Navigate to={`/${process.env.PUBLIC_URL}/404`} replace />}
  //     />
  //   </Routes>
  // );
};

export default AppRoutes;
