import Box, { BoxProps } from "@mui/material/Box";
import { ReactComponent as LogoEqt } from "../assets/logoeqt.svg";

type LogoProps = {
  colored?: boolean;
  size?: number;
} & BoxProps;

const Logo = ({ colored = false, size = 80, ...boxProps }: LogoProps) => {
  return (
    <Box {...boxProps}>
      <LogoEqt height={size} width={size} />
    </Box>
  );
};

export default Logo;
