// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectDatabaseEmulator, getDatabase } from "firebase/database";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firebaseDb = getDatabase(firebaseApp);
const firebaseFunctions = getFunctions(firebaseApp);

const configureEmulators = () => {
  // configure emulators if FB_EMULATOR_IP is set and not in production
  if (
    !process.env.REACT_APP_FB_EMULATOR_IP ||
    process.env.REACT_APP_ENV === "production"
  ) {
    return;
  }

  if (process.env.REACT_APP_SKIP_FUNCTIONS_EMULATOR?.toLowerCase() !== "true") {
    console.log("Using local emulator for functions");
    connectFunctionsEmulator(
      firebaseFunctions,
      process.env.REACT_APP_FB_EMULATOR_IP!,
      5001
    );
  }

  if (process.env.REACT_APP_SKIP_AUTH_EMULATOR?.toLowerCase() !== "true") {
    console.log("Using local emulator for auth");
    connectAuthEmulator(
      firebaseAuth,
      `http://${process.env.REACT_APP_FB_EMULATOR_IP}:9099`
    );
  }

  if (process.env.REACT_APP_SKIP_DATABASE_EMULATOR?.toLowerCase() !== "true") {
    console.log("Using local emulator for database");
    connectDatabaseEmulator(
      firebaseDb,
      process.env.REACT_APP_FB_EMULATOR_IP!,
      9000
    );
  }
};

configureEmulators();

export { firebaseApp, firebaseAuth, firebaseDb, firebaseFunctions };
